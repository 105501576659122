import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'

import Layout from '../components/Layout'

const AboutContainer = styled.section`
  max-width: 42rem;
  & > h2 {
    font-size: 1.4rem;
  }
  @media (min-width: 768px) {
    margin-left: 2rem;
  }
`

const Bio = styled.section`
  font-size: 1.7rem;
  line-height: 1.9rem;
  margin-bottom: 2rem;
`

class About extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    const data = this.props.data.file.childDataJson

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <AboutContainer>
          <Bio>{data.bio}</Bio>
          <hr />
          <h2>Contact</h2>
          <p>
            <a href={`mailto:${data.email}`}>{data.email}</a> <br />
            <a href={data.instagram}>Instagram</a>{' '}
            <a href={data.linkedin}>LinkedIn</a>{' '}
            <a href={data.behance}>Behance</a>
          </p>
          <hr />
          <h2>Experience</h2>
          {data.experience.map(xp => {
            return (
              <div>
                <p>
                  <strong>{xp.company}</strong>
                  <br />
                  {xp.position}
                  <br />
                  {xp.date}
                </p>
              </div>
            )
          })}
        </AboutContainer>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    file(relativePath: { eq: "bio.json" }) {
      childDataJson {
        behance
        bio
        email
        experience {
          company
          date
          position
        }
        instagram
        linkedin
        name
      }
    }
  }
`
